import actions from '../helpers/actions.helper';

export const vulvaeReducer = (state, action) => {
  switch (action.type) {
    case actions.SIGN_IN: {
      return {
        ...state,
        user: action.user,
        userToken: action.userToken,
        errorSignIn: action.errorSignIn
      };
    }
    case actions.SIGN_OUT: {
      return {
        ...state,
        userToken: null,
      };
    }
    case actions.ANONYMIZED_DATABASE: {
      return {
        ...state,
        anonymizedDatabase: action.anonymizedDatabase,
      };
    }
    case actions.GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.notifications,
      };
    }
    case actions.ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.notification, ...state.notifications],
      };
    }
    case actions.GET_INFOS: {
      return {
        ...state,
        infos: action.infos,
      };
    }
    case actions.ADD_INFO: {
      return {
        ...state,
        infos: action.infos,
      };
    }
    case actions.UPDATE_INFO: {
      return {
        ...state,
        infos: state.infos.map(info => {
          if (info._id === action.info._id) {
            return action.info;
          }
          return info;
        })
      };
    }
    case actions.DELETE_INFO: {
      return {
        ...state,
        infos: state.infos.filter((info) => { return info._id !== action.id; }),
      };
    }
    default:
      throw new Error('Unexpected action');
  }
}
