import {
  Route,
  Redirect
} from 'react-router-dom';

import sidebarItems from '../helpers/sidebar-items-helper';

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  const validRoute = sidebarItems.filter(item => item.path === rest.location.pathname).length !== 0;

  return (
    <Route
      {...rest}
      render={
        ({ location }) => (
          isAuthenticated
            ?
            validRoute ?
              (
                children
              ) : <Redirect
                to={{
                  pathname: '/home',
                  state: { from: location }
                }}
              /> : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
            ))
      }
    />
  );
}

export default PrivateRoute;