const actions = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  LOGOUT: 'LOGOUT',
  // Notifications
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  // Infos
  GET_INFOS: 'GET_INFOS',
  ADD_INFO: 'ADD_INFO',
  UPDATE_INFO: 'UPDATE_INFO',
  DELETE_INFO: 'DELETE_INFO',

}

export default actions;
