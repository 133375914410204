const sidebarItems = [
  {
    id: 'home',
    label: 'Base de données anonymisées',
    path: '/home'
  },
  {
    id: 'notifications',
    label: 'Notifications',
    path: '/notifications'
  },
  {
    id: 'infos',
    label: 'Infos',
    path: '/infos'
  },

]

export default sidebarItems;