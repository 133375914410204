import './App.scss';
import './styles/fonts.scss';
import React, { useReducer, lazy, Suspense } from 'react';
import { VulvaeProvider } from './providers/vulvaeProvider';
import { vulvaeReducer } from './reducers/vulvaeReducer';
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import Loader from "react-loader-spinner";
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./pages/Login")), 1000);
  });
});
const Home = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./pages/Home")), 1000);
  });
});
const authState = {
  isLoading: true,
  isSignout: true,
  userToken: null,
};

function App() {
  const state = useReducer(vulvaeReducer, authState);
  // Method to check token on localstorage
  const isAuthenticated = () => {
    const token = localStorage.getItem('userToken');
    try {
      if (token) {
        return true;
      }
      else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  // Method to show loader
  const showLoader = () => {
    return (
      <div className="vlv-app__loader">
        <Loader
          type="Oval"
          color="#170D3F"
          height={40}
          width={40}
        />
      </div>
    )
  }

  return (
    <VulvaeProvider value={state}>
      <div className="vlv-app">
        <ErrorBoundary>
          <Router>
            <Suspense fallback={showLoader()}>
              <Switch>
                <PublicRoute
                  path="/login"
                  isAuthenticated={isAuthenticated()}
                >
                  <Login />
                </PublicRoute>
                <PrivateRoute
                  path="/"
                  isAuthenticated={isAuthenticated()}
                >
                  <Home />
                </PrivateRoute>
              </Switch>
            </Suspense>
          </Router>
          <ToastContainer />
        </ErrorBoundary>
      </div>
    </VulvaeProvider>

  );
}

export default App;
